import React, { useState, useEffect, useRef } from 'react'
import { HashLoader } from 'react-spinners';
import { animations } from 'react-animation';
import 'animate.css';

// AwesomeSlider
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';

// Carousel
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// Logo
import logoImage from './img/logo.avif'

// Image Scrolling
import scrollImage1 from './img/assets/img1.webp'
import scrollImage2 from './img/assets/img2.webp'
import scrollImage3 from './img/assets/img3.webp'
import scrollImage4 from './img/assets/img4.webp'
import scrollImage5 from './img/assets/img5.webp'
import scrollImage6 from './img/assets/img6.webp'
import scrollImage7 from './img/assets/img7.webp'
import scrollImage8 from './img/assets/img8.webp'
import scrollImage9 from './img/assets/img9.webp'

// About Image
import aboutImage1 from './img/assets/img_about_1.avif'
import aboutImage2 from './img/assets/img_about_2.avif'
import quoteLeftSolid from './img/svg/quote-left-solid.svg'
import quoteRightSolid from './img/svg/quote-right-solid.svg'

// Collaboration
import collabAwone from './img/collaboraters/awone.avif'
import collabSparsh from './img/collaboraters/sparsh.avif'
import collabMedinilla from './img/collaboraters/medinilla.avif'

// Form Image
import choiceImage from './img/assets/img_choice.avif'
import ValidCheck from './img/assets/check.avif'

// Doctor Image
import AmitBanshelkikar from './img/doctors/AmitBanshelkikar.avif'
import AmitChaoudhary from './img/doctors/AmitChaoudhary.avif'
import SuhasDhole from './img/doctors/SuhasDhole.avif'
import AdityaSondankar from './img/doctors/AdityaSondankar.avif'
import VijayPhule from './img/doctors/VijayPhule.avif'
import SnehaBhartade from './img/doctors/SnehaBhartade.avif'
import SushmaKawale from './img/doctors/SushmaKawale.avif'

import MobAmitBanshelkikar from './img/doctors/Mob_AmitBanshelkikar.avif'
import MobAmitChaoudhary from './img/doctors/Mob_AmitChaoudhary.avif'
import MobSuhasDhole from './img/doctors/Mob_SuhasDhole.avif'
import MobAdityaSondankar from './img/doctors/Mob_AdityaSondankar.avif'
import MobVijayPhule from './img/doctors/Mob_VijayPhule.avif'
import MobSnehaBhartade from './img/doctors/Mob_SnehaBhartade.avif'
import MobSushmaKawale from './img/doctors/Mob_SushmaKawale.avif'

const AutoplaySlider = withAutoplay(AwesomeSlider);

function Home() {

    // Configuration for Team Carousel
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 480 },
            items: 2,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 480, min: 0 },
            items: 1,
            slidesToSlide: 1
        }
    };

    // Check Visibility
    const [isVisible, setIsVisible] = useState(false);
    const [isVisibleAbout, setIsVisibleAbout] = useState(false);
    const [isVisibleService, setIsVisibleService] = useState(false);
    const [isVisiblePartner, setIsVisiblePartner] = useState(false);
    const [isVisibleFeature, setIsVisibleFeature] = useState(false);
    const [isVisibleDoctor, setIsVisibleDoctor] = useState(false);
    const [isVisibleContact, setIsVisibleContact] = useState(false);

    const observerRef = useRef(null);
    const aboutRef = useRef(null);
    const serviceRef = useRef(null);
    const partnerRef = useRef(null);
    const featureRef = useRef(null);
    const doctorRef = useRef(null);
    const contactRef = useRef(null);

    const observeElement = (ref, setIsVisible) => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                    }
                });
            },
            { threshold: 0.0 }
        );

        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    };

    // Intersection Observer for Contact Section
    useEffect(() => observeElement(aboutRef, setIsVisibleAbout), []);
    useEffect(() => observeElement(serviceRef, setIsVisibleService), []);
    useEffect(() => observeElement(partnerRef, setIsVisiblePartner), []);
    useEffect(() => observeElement(featureRef, setIsVisibleFeature), []);
    useEffect(() => observeElement(featureRef, setIsVisibleDoctor), []);
    useEffect(() => observeElement(contactRef, setIsVisibleContact), []);

    const fadeInAnimation = {
        animation: animations.fadeIn,
    };

    const slideInLeftAnimation = {
        animation: animations.slideInLeft,
    };

    // Navbar Active
    const [activeSection, setActiveSection] = useState("home");

    const handleScroll = () => {
        const sections = document.querySelectorAll("section");
        let currentSection = "home";

        sections.forEach((section) => {
            const sectionTop = section.offsetTop - 50; // Adjust for navbar height
            const sectionHeight = section.offsetHeight;
            if (window.scrollY >= sectionTop && window.scrollY < sectionTop + sectionHeight) {
                currentSection = section.id; // Get section id
            }
        });

        setActiveSection(currentSection);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    // Navbar Toggling
    const [toggle, setToggle] = useState('collapse navbar-collapse mobile-view navbar-hide')
    const [lastScrollY, setLastScrollY] = useState(0);

    const toggleNavbar = () => {
        setToggle('collapse navbar-collapse mobile-view')
    }

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;

            // If scrolling down, hide the navbar; if scrolling up, show it
            if (currentScrollY > lastScrollY) {
                setToggle('collapse navbar-collapse mobile-view navbar-hide'); // Hide navbar
            }

            // If scrolling up, hide the navbar; if scrolling down, show it
            if (currentScrollY < lastScrollY) {
                setToggle('collapse navbar-collapse mobile-view navbar-hide'); // Hide navbar
            }

            setLastScrollY(currentScrollY);
        };

        // Add the scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up event listener on unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollY]);

    // Form Operations
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [enquiry, setEnquiry] = useState('')
    const [desc, setDesc] = useState('')
    const [status, setStatus] = useState('FAILED')

    const [nameAlert, setNameAlert] = useState('alert-hide')
    const [phoneAlert, setPhoneAlert] = useState('alert-hide')
    const [invalidPhoneAlert, setInvalidPhoneAlert] = useState('alert-hide')
    const [enquiryAlert, setEnquiryAlert] = useState('alert-hide')
    const [descAlert, setDescAlert] = useState('alert-hide')

    const [formData, setFormData] = useState({
        timeStamp: new Date().toLocaleString(),
        fullName: '',
        phoneNumber: '',
        enquiryRegarding: '',
        description: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleChangePhone = (n) => {
        console.log(n.length)
        if (n.length > 10 || n.length < 10) {
            setInvalidPhoneAlert('alert-form')
        } else {
            setInvalidPhoneAlert('alert-hide')
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (name == '' || enquiry == '' || phone == '' || desc == '') {
            if (name == '') {
                setNameAlert('alert-form')
            }
            if (phone == '') {
                setPhoneAlert('alert-form')
            } else if (phone.length != 10) {
                setInvalidPhoneAlert('alert-form')
            }
            if (enquiry == '') {
                setEnquiryAlert('alert-form')
            }
            if (desc == '') {
                setDescAlert('alert-form')
            }
        } else {
            setFormView("bg-light rounded p-4 hide-contact")
            setLoadingView("bg-light rounded loading")
            setThanksView("bg-light rounded p-4 h-100 thanks hide-all")

            const data = new FormData();
            data.append('timeStamp', formData.timeStamp);
            data.append('fullName', formData.fullName);
            data.append('phoneNumber', formData.phoneNumber);
            data.append('enquiryRegarding', formData.enquiryRegarding);
            data.append('description', formData.description);

            const Sheet_Url = "https://script.google.com/macros/s/AKfycbyqz_ngh_ObScM7KmtgZ7bXRNvjJ8y3SD0S0xk8vIhG3EFUD228UkOpqMw6OqMn_3Gp/exec"

            try {
                const result = await fetch(Sheet_Url, {
                    method: 'POST',
                    body: data,
                    muteHttpExceptions: true,
                });

                if (result) {
                    setFormData({
                        timeStamp: new Date().toLocaleString(),
                        fullName: '',
                        phoneNumber: '',
                        enquiryRegarding: '',
                        description: ''
                    });
                    setStatus('SUCCESS')
                    setFormView("bg-light rounded p-4 hide-contact")
                    setLoadingView("bg-light rounded p-4 loading hide-loading")
                    setThanksView("bg-light rounded p-4 h-100 thanks get-all")
                }
            } catch (error) {
                console.log(error);
                setStatus('FAILED')
            }
        }
    };

    // Form Response
    const [formView, setFormView] = useState("bg-light rounded p-4")
    const [loadingView, setLoadingView] = useState("bg-light rounded loading hide-loading")
    const [thanksView, setThanksView] = useState("bg-light rounded thanks hide-all")

    // Navbar Scroll

    return (
        <div>
            {/* <!-- Topbar Start --> */}
            <div className="container-fluid bg-light p-0 wow fadeIn" data-wow-delay="0.1s">
                <div className="row gx-0 d-none d-lg-flex">
                    <div className="col-lg-7 px-5 text-start">
                        <a href="https://maps.app.goo.gl/Bm2AJZaUszMtkwfp7" target="_blank" aria-label="Google Map Location">
                            <div className="h-100 d-inline-flex align-items-center py-3 me-4">
                                <small className="fa fa-map-marker-alt text-primary me-2"></small>
                                <small className="color-black">Mediclinic, Shantai Classic, beside Medinilla Healthcare Pvt. Ltd., Ravet, Pune, Maharashtra 412101</small>
                            </div>
                        </a>
                    </div>
                    <div className="col-lg-5 px-5 text-end">
                        <div className="h-100 d-inline-flex align-items-center py-3 me-4">
                            <a href="tel:+919145324365" target="_blank" aria-label="Contact Number">
                                <small className="fa fa-phone-alt text-primary me-2"></small>
                                <small className="color-black">+91 91453 24365</small>
                            </a>
                        </div>
                        <div className="h-100 d-inline-flex align-items-center">
                            <a className="btn btn-sm-square rounded-circle bg-white text-primary me-1 hover-blue" href="https://facebook.com/61568338618033" target="_blank" aria-label="Facebook"><i className="fab fa-facebook-f"></i></a>
                            <a className="btn btn-sm-square rounded-circle bg-white text-primary me-1 hover-blue" href="https://wa.me/919145324365" target="_blank"><i className="fab fa-whatsapp" aria-label="Whatsapp"></i></a>
                            <a className="btn btn-sm-square rounded-circle bg-white text-primary me-0 hover-blue" href="https://www.instagram.com/mediclinic2024/" target="_blank" aria-label="Instagram"><i className="fab fa-instagram"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Topbar End --> */}

            {/* <!-- Navbar Start --> */}
            <nav className="navbar-expand-lg bg-white navbar-light sticky-nav p-0 wow fadeIn navbar" data-wow-delay="0.1s">
                <a href="/" className="navbar-brand d-flex align-items-center px-4 px-lg-5" aria-label="Home">
                    <h1 className="m-0 text-primary"><img className="logo-img" src={logoImage} alt="MediClinic" /></h1>
                </a>

                {/* Appointment Button */}
                <a href="https://www.eka.care/doctor/dr-mediclinic-general-physician-pimpri-chinchwad" className="nav-item nav-link bookappointmob navbar-mobile" target="_blank" aria-label="Book Appointment">Book Appointment<br />Now</a>
                <button id="menu-icon" type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" onClick={toggleNavbar} aria-label="menu">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={toggle} id="navbarCollapse">
                    <div className="navbar-mobile navbar-nav ms-auto p-4 p-lg-0">
                        <a href="/" className="nav-item nav-link active" aria-label="Home">Home</a>
                        <a href="#about" className="nav-item nav-link" onClick={() => {
                            setToggle('collapse navbar-collapse mobile-view navbar-hide')
                        }} aria-label="About Us">About Us</a>
                        <a href="#service" className="nav-item nav-link" onClick={() => {
                            setToggle('collapse navbar-collapse mobile-view navbar-hide')
                        }} aria-label="Service">Service</a>
                        <a href="#collaboration" className="nav-item nav-link" onClick={() => {
                            setToggle('collapse navbar-collapse mobile-view navbar-hide')
                        }} aria-label="Collaboration">PARTNERSHIPS</a>
                        <a href="#features" className="nav-item nav-link" onClick={() => {
                            setToggle('collapse navbar-collapse mobile-view navbar-hide')
                        }} aria-label="Feature">Feature</a>
                        <a href="#doctor" className="nav-item nav-link" onClick={() => {
                            setToggle('collapse navbar-collapse mobile-view navbar-hide')
                        }} aria-label="Doctor">Doctor</a>
                        <a href="#contact" className="nav-item nav-link" onClick={() => {
                            setToggle('collapse navbar-collapse mobile-view navbar-hide')
                        }} aria-label="Contact Us">Contact Us</a>
                    </div>
                </div>

                {/* Desktop View for Navbar */}
                <div className="collapse navbar-collapse desktop-view" id="navbarCollapse">
                    <div className="navbar navbar-nav collapse-right ms-auto p-4 p-lg-0">
                        <a href="/" className={`nav-item nav-link ${activeSection === "home" ? "active" : ""}`}
                            aria-label="Home">HOME</a>
                        <a href="#about" className={`nav-item nav-link ${activeSection === "about" ? "active" : ""}`} aria-label="About Us">ABOUT US</a>
                        <a href="#service" className={`nav-item nav-link ${activeSection === "service" ? "active" : ""}`} aria-label="Service">SERVICE</a>
                        <a href="#collaboration" className={`nav-item nav-link ${activeSection === "collaboration" ? "active" : ""}`} aria-label="Collaboration">PARTNERSHIPS</a>
                        <a href="#features" className={`nav-item nav-link ${activeSection === "features" ? "active" : ""}`} aria-label="Feature">FEATURE</a>
                        <a href="#doctor" className={`nav-item nav-link ${activeSection === "doctor" ? "active" : ""}`} aria-label="Doctor">Doctor</a>
                        <a href="#contact" className={`nav-item nav-link ${activeSection === "contact" ? "active" : ""}`} aria-label="Contact Us">CONTACT US</a>
                        <a href="https://www.eka.care/doctor/dr-mediclinic-general-physician-pimpri-chinchwad" className="nav-item nav-link bookappoint" target="_blank" aria-label="Book Appointment">BOOK APPOINTMENT NOW</a>
                    </div>
                </div>

            </nav>
            {/* <!-- Navbar End --> */}

            {/* <!-- Header Start --> */}
            <section className="home show-animate" id="home">
                <div className="col-lg-12 wow fadeIn" data-wow-delay="0.5s">
                    <div className="owl-carousel header-carousel">
                        <AutoplaySlider play={true} cancelOnInteraction={false} interval={3000}>
                            <div className="img-fluid" data-src={scrollImage1} alt='Gynecologist'>
                                <div className="owl-carousel-text">
                                    <h1 className="display-1 text-white mb-0">Gynecologist</h1>
                                </div>
                            </div>
                            <div className="img-fluid" data-src={scrollImage2} alt='Dietician'>
                                <div className="owl-carousel-text">
                                    <h1 className="display-1 text-white mb-0">Dietician</h1>
                                </div>
                            </div>
                            <div className="img-fluid" data-src={scrollImage3} alt='Pathology Lab'>
                                <div className="owl-carousel-text">
                                    <h1 className="display-1 text-white mb-0">Pathology Lab</h1>
                                </div>
                            </div>
                            <div className="img-fluid" data-src={scrollImage4} alt='Physiotherapist'>
                                <div className="owl-carousel-text">
                                    <h1 className="display-1 text-white mb-0">Physiotherapist</h1>
                                </div>
                            </div>
                            <div className="img-fluid" data-src={scrollImage5} alt='Physician'>
                                <div className="owl-carousel-text">
                                    <h1 className="display-1 text-white mb-0">Physician</h1>
                                </div>
                            </div>
                            <div className="img-fluid" data-src={scrollImage6} alt='Orthopedics'>
                                <div className="owl-carousel-text">
                                    <h1 className="display-1 text-white mb-0">Orthopedics</h1>
                                </div>
                            </div>
                            <div className="img-fluid" data-src={scrollImage7} alt='Pediatrician'>
                                <div className="owl-carousel-text">
                                    <h1 className="display-1 text-white mb-0">Pediatrician</h1>
                                </div>
                            </div>
                            <div className="img-fluid" data-src={scrollImage8} alt='Diagnostic'>
                                <div className="owl-carousel-text">
                                    <h1 className="display-1 text-white mb-0">Diagnostic</h1>
                                </div>
                            </div>
                            <div className="img-fluid" data-src={scrollImage9} alt='Dentist'>
                                <div className="owl-carousel-text">
                                    <h1 className="display-1 text-white mb-0">Dentist</h1>
                                </div>
                            </div>
                        </AutoplaySlider>
                    </div>
                </div>
            </section>
            {/* <!-- Header End --> */}

            {/* <!-- About Start --> */}
            <section className="about" id="about">
                <div className="container-xxl mt-5 py-5" style={{ animation: isVisibleAbout ? 'fadeInUp 1s' : 'none' }} ref={aboutRef}>
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                                <div className="d-flex flex-column">
                                    <img className="img-fluid rounded w-75 align-self-end" src={aboutImage1} alt="About Image" />
                                    <img className="img-fluid rounded w-50 pt-3 pe-3" src={aboutImage2} alt="About Image" style={{ marginTop: '-25%' }} />
                                </div>
                            </div>
                            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                                <p className="d-inline-block border rounded-pill py-1 px-4">About Us</p>
                                <h1 className="mb-4">Why You Should Trust Us?<br /> Get Know About Us!</h1>
                                <p>At Mediclinic, your health is in expert hands. Our experienced team of doctors and healthcare professionals is committed to delivering high-quality, compassionate care using the latest medical technology. We follow strict safety standards and provide clear, patient-centered care you can rely on.
                                </p><br />
                                <i className="fa-solid fa-horizontal-rule"></i><h1 className="our-mission">Our Mission</h1><i className="fa-solid fa-horizontal-rule"></i>
                                <p className="mb-4 text-align-center"><img className="svg-class" src={quoteLeftSolid} alt="Quote Left"></img><i><b> To Provide Caring, High-Quality Treatment to Enhance Patient Health and Well-being. We strive to Improve Outcomes through Personalized Care and Support. </b></i><img className="svg-class" src={quoteRightSolid} alt="Quote Right"></img></p>
                                <p><i className="far fa-check-circle text-primary me-3"></i>Quality health care</p>
                                <p><i className="far fa-check-circle text-primary me-3"></i>Professionals & Qualified Doctors</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            {/* <!-- About End --> */}

            {/* <!-- Service Start --> */}
            <section className="service" id="service">
                <div className="container-xxl mt-5 py-5" style={{ animation: isVisibleService ? 'fadeInUp 1s' : 'none' }} ref={serviceRef}>
                    <div className="container">
                        <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '600px' }}>
                            <p className="d-inline-block border rounded-pill py-1 px-4">Services</p>
                            <h1>Health Care Solutions</h1>
                        </div>
                        <div className="row g-4">
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s" id="gynecologist">
                                <div className="service-item bg-light rounded h-100 p-5">
                                    <div className="d-inline-flex align-items-center justify-content-center bg-white rounded-circle mb-4" style={{ width: '65px', height: '65px' }}>
                                        <i className="fa fa-user-md text-primary fs-4"></i>
                                    </div>
                                    <h4 className="mb-3">Gynecologist</h4>
                                    <p className="mb-4 text-align-justify">Our gynecology specialists focus on every aspect of women’s reproductive health. From routine exams to prenatal care and treating specific conditions, we’re here to support and guide you at every stage of life.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s" id="dietician">
                                <div className="service-item bg-light rounded h-100 p-5">
                                    <div className="d-inline-flex align-items-center justify-content-center bg-white rounded-circle mb-4" style={{ width: '65px', height: '65px' }}>
                                        <i className="fa fa-plus text-primary fs-4"></i>
                                    </div>
                                    <h4 className="mb-3">Dietician</h4>
                                    <p className="mb-4 text-align-justify">
                                        Our dietitians provide personalized nutrition advice to support your health goals, creating diet plans tailored to boost wellness and manage specific conditions.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s" id="pathology">
                                <div className="service-item bg-light rounded h-100 p-5">
                                    <div className="d-inline-flex align-items-center justify-content-center bg-white rounded-circle mb-4" style={{ width: '65px', height: '65px' }}>
                                        <i className="fa fa-vials text-primary fs-4"></i>
                                    </div>
                                    <h4 className="mb-3">Pathology Lab</h4>
                                    <p className="mb-4 text-align-justify">
                                        Our pathology lab specializes in precise diagnostics, analyzing blood, tissue, and other samples to support accurate treatment decisions and improve patient care.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s" id="physiotherapist">
                                <div className="service-item bg-light rounded h-100 p-5">
                                    <div className="d-inline-flex align-items-center justify-content-center bg-white rounded-circle mb-4" style={{ width: '65px', height: '65px' }}>
                                        <i className="fa fa-comment-medical text-primary fs-4"></i>
                                    </div>
                                    <h4 className="mb-3">Physiotherapist</h4>
                                    <p className="mb-4 text-align-justify">Our physiotherapists are here to help you regain movement and reduce pain with personalized exercise programs and hands-on therapy, supporting your recovery and long-term physical health.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s" id="physician">
                                <div className="service-item bg-light rounded h-100 p-5">
                                    <div className="d-inline-flex align-items-center justify-content-center bg-white rounded-circle mb-4" style={{ width: '65px', height: '65px' }}>
                                        <i className="fa fa-check-circle text-primary fs-4"></i>
                                    </div>
                                    <h4 className="mb-3">Physician</h4>
                                    <p className="mb-4 text-align-justify">Our physicians provide comprehensive medical care, diagnosing and treating a wide range of health issues while offering personalized guidance to support your well-being.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s" id="orthopedics">
                                <div className="service-item bg-light rounded h-100 p-5">
                                    <div className="d-inline-flex align-items-center justify-content-center bg-white rounded-circle mb-4" style={{ width: '65px', height: '65px' }}>
                                        <i className="fa fa-x-ray text-primary fs-4"></i>
                                    </div>
                                    <h4 className="mb-3">Orthopedics</h4>
                                    <p className="mb-4 text-align-justify">
                                        Our orthopedic specialists are dedicated to treating bone, joint, and muscle issues, helping you manage injuries and improve musculoskeletal health for a more active life.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s" id="pediatrician">
                                <div className="service-item bg-light rounded h-100 p-5">
                                    <div className="d-inline-flex align-items-center justify-content-center bg-white rounded-circle mb-4" style={{ width: '65px', height: '65px' }}>
                                        <i className="fa fa-solid fa-child text-primary fs-4"></i>
                                    </div>
                                    <h4 className="mb-3">Pediatrician</h4>
                                    <p className="mb-4 text-align-justify">Our Pediatricians specialize in children's health, providing care that focuses on growth, preventive measures, and treatment of illnesses from infancy through adolescence.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s" id="diagnostic">
                                <div className="service-item bg-light rounded h-100 p-5">
                                    <div className="d-inline-flex align-items-center justify-content-center bg-white rounded-circle mb-4" style={{ width: '65px', height: '65px' }}>
                                        <i className="fa fa-solid fa-comment-medical text-primary fs-4"></i>
                                    </div>
                                    <h4 className="mb-3">Diagnostic</h4>
                                    <p className="mb-4 text-align-justify">
                                        Our Diagnostic services provide thorough testing and evaluation of samples like blood and tissue to accurately identify diseases, helping guide effective treatment and care.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s" id="dentist">
                                <div className="service-item bg-light rounded h-100 p-5">
                                    <div className="d-inline-flex align-items-center justify-content-center bg-white rounded-circle mb-4" style={{ width: '65px', height: '65px' }}>
                                        <i className="fa fa-tooth text-primary fs-4"></i>
                                    </div>
                                    <h4 className="mb-3">Dentist</h4>
                                    <p className="mb-4 text-align-justify">
                                        Our Dentists focus on your oral health, offering comprehensive care that includes diagnosing and treating dental issues, providing preventive services, and promoting good dental hygiene for all ages.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Service End --> */}

            {/* <!-- Sparsh Start --> */}
            <section className="service" id="collaboration">
                <div className="container-xxl mt-5 py-5" style={{ animation: isVisiblePartner ? 'fadeInUp 1s' : 'none' }} ref={partnerRef}>
                    <div className="container">
                        <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '600px' }}>
                            <h1>Our Partners</h1>
                        </div>
                        <div className="row g-4">
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="service-item collaborate-item bg-light rounded h-100 p-5">
                                    <div className="d-inline-flex align-items-center justify-content-center mb-4" style={{ width: '65px', height: '65px' }}>
                                        <img src={collabAwone} alt='AWONE Logo' />
                                    </div>
                                    <p className="text-align-justify">Offers high-quality medical products, including diagnostic tools, treatment supplies, and protective equipment, designed to enhance patient care and improve healthcare outcomes.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="service-item collaborate-item bg-light rounded h-100 p-5">
                                    <div className="d-inline-flex align-items-center justify-content-center mb-4" style={{ width: '65px', height: '65px' }}>
                                        <img src={collabSparsh} alt='Sparsh Logo' />
                                    </div>
                                    <p className="text-align-justify">multi-specialty tertiary care facility offering advanced treatments with a team of globally trained doctors, cutting-edge technology, and 24/7 Level 1 Trauma Care, ensuring top-quality healthcare for all patients.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                                <div className="service-item collaborate-item bg-light rounded h-100 p-5">
                                    <div className="d-inline-flex align-items-center justify-content-center mb-4" style={{ width: '65px', height: '65px' }}>
                                        <img src={collabMedinilla} alt='Medinilla Logo' />
                                    </div>
                                    <p className="text-align-justify">provides a wide range of medical tablets designed to effectively treat various health conditions, ensuring quality, safety, and efficacy. Our pharmaceutical products support patient recovery and promote overall well-being.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Sparsh End --> */}

            {/* <!-- Feature Start --> */}
            <section className="features" id="features">
                <div className="container-fluid bg-primary overflow-hidden my-5 px-lg-0">
                    <div className="container feature px-lg-0" style={{ animation: isVisibleFeature ? 'fadeInUp 1s' : 'none' }} ref={featureRef}>
                        <div className="row g-0 mx-lg-0">
                            <div className="col-lg-6 feature-text py-5 wow fadeIn" data-wow-delay="0.1s">
                                <div className="p-lg-5 ps-lg-0">
                                    <p className="d-inline-block border rounded-pill text-light py-1 px-4">Features</p>
                                    <h1 className="text-white mb-4">Why Choose Us</h1>
                                    <p className="text-white mb-4 pb-2">We provide experienced doctors, top-notch service, and friendly consultations. Our lab delivers quick results, and we're available 24/7. We also offer a variety of vaccinations for your convenience and health.</p>
                                    <div className="row g-4">
                                        <div className="col-6">
                                            <div className="d-flex align-items-center">
                                                <div className="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-light" style={{ width: '55px', height: '55px' }}>
                                                    <i className="fa fa-user-md text-primary"></i>
                                                </div>
                                                <div className="ms-4">
                                                    <p className="text-white mb-2">Experience</p>
                                                    <h5 className="text-white mb-0">Doctors</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="d-flex align-items-center">
                                                <div className="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-light" style={{ width: '55px', height: '55px' }}>
                                                    <i className="fa fa-check text-primary"></i>
                                                </div>
                                                <div className="ms-4">
                                                    <p className="text-white mb-2">Quality</p>
                                                    <h5 className="text-white mb-0">Services</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="d-flex align-items-center">
                                                <div className="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-light" style={{ width: '55px', height: '55px' }}>
                                                    <i className="fa fa-comment-medical text-primary"></i>
                                                </div>
                                                <div className="ms-4">
                                                    <p className="text-white mb-2">Positive</p>
                                                    <h5 className="text-white mb-0">Consultation</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="d-flex align-items-center">
                                                <div className="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-light" style={{ width: '55px', height: '55px' }}>
                                                    <i className="fa fa-solid fa-x-ray text-primary"></i>
                                                </div>
                                                <div className="ms-4">
                                                    <p className="text-white mb-2">Available</p>
                                                    <h5 className="text-white mb-0">X-Ray & Diagnostic</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="d-flex align-items-center">
                                                <div className="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-light" style={{ width: '55px', height: '55px' }}>
                                                    <i className="fa fa-clock text-primary"></i>
                                                </div>
                                                <div className="ms-4">
                                                    <p className="text-white mb-2">Available</p>
                                                    <h5 className="text-white mb-0">24 / 7</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="d-flex align-items-center">
                                                <div className="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-light" style={{ width: '55px', height: '55px' }}>
                                                    <i className="fa fa-plus text-primary"></i>
                                                </div>
                                                <div className="ms-4">
                                                    <p className="text-white mb-2">Multiple</p>
                                                    <h5 className="text-white mb-0">Vaccination</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="d-flex align-items-center">
                                                <div className="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-light" style={{ width: '55px', height: '55px' }}>
                                                    <i className="fa fa-vials text-primary"></i>
                                                </div>
                                                <div className="ms-4">
                                                    <p className="text-white mb-2">All Types</p>
                                                    <h5 className="text-white mb-0">Laboratory Reports</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="d-flex align-items-center">
                                                <div className="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-light" style={{ width: '55px', height: '55px' }}>
                                                    <i className="fa fa-pills text-primary"></i>
                                                </div>
                                                <div className="ms-4">
                                                    <p className="text-white mb-2">Medicine Available</p>
                                                    <h5 className="text-white mb-0">Generic Standard</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 pe-lg-0 wow fadeIn" data-wow-delay="0.5s" style={{ minHeight: '400px' }}>
                                <div className="position-relative h-100">
                                    <img className="position-absolute img-fluid w-100 h-100" src={choiceImage} style={{ objectFit: 'cover' }} alt="Choice Image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Feature End --> */}

            {/* <!-- Team Start --> */}
            <section className="doctors" id="doctor" >
                <div className="container-fluid my-5 py-5">
                    <div className="container" style={{ animation: isVisibleDoctor ? 'fadeInUp 1s' : 'none' }} ref={doctorRef}>
                        <div className="text-center mx-auto mb-5" style={{ maxWidth: '500px' }}>
                            <h5 className="d-inline-block text-primary text-uppercase border-bottom border-5">Our Doctors</h5>
                            <h1 className="display-4">Qualified Healthcare Professionals</h1>
                        </div>
                        <div className="owl-carousel team-carousel position-relative carousel-desktop">
                            <Carousel
                                swipeable={false}
                                draggable={false}
                                showDots={false}
                                responsive={responsive}
                                ssr={true} // means to render carousel on server-side.
                                infinite={true}
                                autoPlay={true}
                                autoPlaySpeed={3000}
                                keyBoardControl={false}
                                customTransition="transform 750ms ease-in-out"
                                transitionDuration={5000}
                                containerClass="team-carousel"
                                dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-40-px"
                            >
                                <div className="team-item bg-light">
                                    <div className="row g-0 bg-light rounded overflow-hidden">
                                        <div className="col-12 col-sm-5 h-100 img-team">
                                            <img className="img-fluid h-100" src={AmitBanshelkikar} style={{ objectFit: 'cover' }} alt='Orthopedic Doctor Image' />
                                        </div>
                                        <div className="col-12 col-sm-7 h-100 d-flex flex-column">
                                            <div className="mt-auto p-4">
                                                <h3>Dr. Amit Banshelkikar</h3>
                                                <p>M.B.B.S.D.Ortho</p>
                                                <h6 className="fw-normal fst-italic text-primary mb-4">Orthopedic Surgeon</h6>
                                                <p className="m-0">An expert orthopedic surgeon dedicated to improving patients' mobility and quality of life</p>
                                            </div>
                                            <div className="d-flex mt-auto border-top p-4">
                                                <p className="m-0"><b>Experience: </b>12+ Years</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-item bg-light">
                                    <div className="row g-0 bg-light rounded overflow-hidden">
                                        <div className="col-12 col-sm-5 h-100 img-team">
                                            <img className="img-fluid h-100" src={AmitChaoudhary} style={{ objectFit: 'cover' }} alt='Dietician Doctor Image' />
                                        </div>
                                        <div className="col-12 col-sm-7 h-100 d-flex flex-column">
                                            <div className="mt-auto p-4">
                                                <h3>Dr. Amit Kumar Chaoudhary</h3>
                                                <p>M.B.B.S., M.D., Dr.NB</p>
                                                <h6 className="fw-normal fst-italic text-primary mb-4">Critical Care Medicine</h6>
                                                <p className="m-0">An M.B.B.S., M.D., and DrNB in Critical Care Medicine with 10+ Years of Experience, Specializes in Advanced, Life-Critical Care with Super-Specialty Expertise.</p>
                                            </div>
                                            <div className="d-flex mt-auto border-top p-4">
                                                <p className="m-0"><b>Experience: </b>10+ Years</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-item bg-light">
                                    <div className="row g-0 bg-light rounded overflow-hidden">
                                        <div className="col-12 col-sm-5 h-100 img-team">
                                            <img className="img-fluid h-100" src={SuhasDhole} style={{ objectFit: 'cover' }} alt='Critical Care Doctor Image' />
                                        </div>
                                        <div className="col-12 col-sm-7 h-100 d-flex flex-column">
                                            <div className="mt-auto p-4">
                                                <h3>Dr. Suhas Sadashiv Dhole</h3>
                                                <p>M.B.B.S., M.D., I.D.C.C.M</p>
                                                <h6 className="fw-normal fst-italic text-primary mb-4">Critical Care Medicine</h6>
                                                <p className="m-0">An expert orthopedic surgeon dedicated to improving patients' mobility and quality of life</p>
                                            </div>
                                            <div className="d-flex mt-auto border-top p-4">
                                                <p className="m-0"><b>Experience: </b>9+ Years</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-item bg-light">
                                    <div className="row g-0 bg-light rounded overflow-hidden">
                                        <div className="col-12 col-sm-5 h-100 img-team">
                                            <img className="img-fluid h-100" src={AdityaSondankar} style={{ objectFit: 'cover' }} alt='Consultant, Physician, Diabetologist and Critical Care Specialist Doctor Image' />
                                        </div>
                                        <div className="col-12 col-sm-7 h-100 d-flex flex-column">
                                            <div className="mt-auto p-4">
                                                <h3>Dr. Aditya Vinod Sondankar</h3>
                                                <p>M.B.B.S., D.N.B. MEDICINE, M.C. in Diabetes,P.G.D.C.E.D.</p>
                                                <h6 className="fw-normal fst-italic text-primary mb-4">Consultant, Physician, Diabetologist and Critical Care Specialist</h6>
                                                <p className="m-0">Healthcare Expert providing Comprehensive Medical Solutions as a Consultant Physician, Diabetologist, and Critical Care Specialist.</p>
                                            </div>
                                            <div className="d-flex mt-auto border-top p-4">
                                                <p className="m-0"><b>Experience: </b>7+ Years</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-item bg-light">
                                    <div className="row g-0 bg-light rounded overflow-hidden">
                                        <div className="col-12 col-sm-5 h-100 img-team">
                                            <img className="img-fluid h-100" src={SushmaKawale} style={{ objectFit: 'cover' }} alt='Dental Surgeon Image' />
                                        </div>
                                        <div className="col-12 col-sm-7 h-100 d-flex flex-column">
                                            <div className="mt-auto p-4">
                                                <h3>Dr. Sushma Shankar Kawale</h3>
                                                <p>B.D.S.</p>
                                                <h6 className="fw-normal fst-italic text-primary mb-4">Dental Surgeon</h6>
                                                <p className="m-0">Skilled and Experienced Dental Surgeon providing Personalized and Comprehensive Dental Care Solutions</p>
                                            </div>
                                            <div className="d-flex mt-auto border-top p-4">
                                                <p className="m-0"><b>Experience: </b>8+ Years</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-item bg-light">
                                    <div className="row g-0 bg-light rounded overflow-hidden">
                                        <div className="col-12 col-sm-5 h-100 img-team">
                                            <img className="img-fluid h-100" src={SnehaBhartade} style={{ objectFit: 'cover' }} alt='Critical Care Doctor Image' />
                                        </div>
                                        <div className="col-12 col-sm-7 h-100 d-flex flex-column">
                                            <div className="mt-auto p-4">
                                                <h3>Dr. Sneha Bhatade</h3>
                                                <p>M.B.B.S.</p>
                                                <h6 className="fw-normal fst-italic text-primary mb-4">Critical Care Medicine</h6>
                                                <p className="m-0">An Expert Critical Care Surgeon dedicated to improving Patients' Mobility and Quality of Life</p>
                                            </div>
                                            <div className="d-flex mt-auto border-top p-4">
                                                <p className="m-0"><b>Experience: </b>15+ Years</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="team-item bg-light">
                                    <div className="row g-0 bg-light rounded overflow-hidden">
                                        <div className="col-12 col-sm-5 h-100 img-team">
                                            <img className="img-fluid h-100" src={VijayPhule} style={{ objectFit: 'cover' }} alt='Critical Care Doctor Image' />
                                        </div>
                                        <div className="col-12 col-sm-7 h-100 d-flex flex-column">
                                            <div className="mt-auto p-4">
                                                <h3>Dr. Vijay Kisan Phule</h3>
                                                <p>M.B.B.S.</p>
                                                <h6 className="fw-normal fst-italic text-primary mb-4">Critical Care Medicine</h6>
                                                <p className="m-0">Experienced and Compassionate Medical Expert dedicated to Delivering Exceptional Patient Care and Critical Medical Management.</p>
                                            </div>
                                            <div className="d-flex mt-auto border-top p-4">
                                                <p className="m-0"><b>Experience: </b>12+ Years</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Carousel>
                        </div>
                        <div className="owl-carousel team-carousel position-relative carousel-mobile">
                            <Carousel
                                swipeable={false}
                                draggable={false}
                                showDots={false}
                                responsive={responsive}
                                ssr={true} // means to render carousel on server-side.
                                infinite={true}
                                autoPlay={true}
                                autoPlaySpeed={3000}
                                keyBoardControl={false}
                                customTransition="transform 750ms ease-in-out"
                                transitionDuration={5000}
                                containerClass="team-carousel"
                                dotListClass="custom-dot-list-style"
                                itemClass="carousel-item-padding-40-px"
                            >
                                <div className="bg-light">
                                    <div className="row g-0 bg-light rounded overflow-hidden">
                                        <div className="col-12 col-sm-5 h-100 img-team">
                                            <img className="img-fluid h-100" src={MobAmitBanshelkikar} style={{ objectFit: 'cover' }} alt='Orthopedic Doctor Image' />
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-light">
                                    <div className="row g-0 bg-light rounded overflow-hidden">
                                        <div className="col-12 col-sm-5 h-100 img-team">
                                            <img className="img-fluid h-100" src={MobAmitChaoudhary} style={{ objectFit: 'cover' }} alt='Dietician Doctor Image' />
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-light">
                                    <div className="row g-0 bg-light rounded overflow-hidden">
                                        <div className="col-12 col-sm-5 h-100 img-team">
                                            <img className="img-fluid h-100" src={MobSuhasDhole} style={{ objectFit: 'cover' }} alt='Orthopedic Doctor Image' />
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-light">
                                    <div className="row g-0 bg-light rounded overflow-hidden">
                                        <div className="col-12 col-sm-5 h-100 img-team">
                                            <img className="img-fluid h-100" src={MobAdityaSondankar} style={{ objectFit: 'cover' }} alt='Consultant, Physician, Diabetologist and Critical Care Specialist Doctor Image' />
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-light">
                                    <div className="row g-0 bg-light rounded overflow-hidden">
                                        <div className="col-12 col-sm-5 h-100 img-team">
                                            <img className="img-fluid h-100" src={MobSushmaKawale} style={{ objectFit: 'cover' }} alt='Dental Surgeon Image' />
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-light">
                                    <div className="row g-0 bg-light rounded overflow-hidden">
                                        <div className="col-12 col-sm-5 h-100 img-team">
                                            <img className="img-fluid h-100" src={MobSnehaBhartade} style={{ objectFit: 'cover' }} alt='Critical Care Doctor Image' />
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-light">
                                    <div className="row g-0 bg-light rounded overflow-hidden">
                                        <div className="col-12 col-sm-5 h-100 img-team">
                                            <img className="img-fluid h-100" src={MobVijayPhule} style={{ objectFit: 'cover' }} alt='Critical Care Doctor Image' />
                                        </div>
                                    </div>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Team End --> */}

            {/* <!-- contact Start --> */}
            <section className="contact" id="contact">
                <div className="container-xxl py-5" style={{ animation: isVisibleContact ? 'fadeInUp 1s' : 'none' }} ref={contactRef}>
                    <div className="container">
                        <div className="text-center mx-auto mb-5" style={{ maxWidth: '500px' }}>
                            <h5 className="d-inline-block text-primary text-uppercase border-bottom border-5">CONTACT US NOW</h5>
                        </div>
                        <div className="row g-5">
                            <div className="col-lg-6 wow fadeIn contact-area" data-wow-delay="0.1s">
                                <div className={formView}>
                                    <div>
                                        <h1>Have Any Query?<br />Feel Free to Contact Us!</h1>
                                        <p>We’d love to hear from you! Whether you have a question, feedback, or need assistance, please fill out the form below, and our team will get back to you as soon as possible.</p>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className='form-ele'>
                                            <label for="full-name">Full Name</label>
                                            <input type='text' name='fullName' value={formData.fullName} onChange={(e) => {
                                                setName(e.target.value);
                                                setNameAlert('alert-hide');
                                                handleChange(e)
                                            }} id="full-name" />
                                            <p className={nameAlert}>Please Enter Your Name</p>
                                        </div>
                                        <div className='form-ele'>
                                            <label for="phone-number">Phone Number</label>
                                            <input type='number' name='phoneNumber' value={formData.phoneNumber} onChange={(e) => {
                                                setPhoneAlert('alert-hide');
                                                setPhone(e.target.value);
                                                handleChangePhone(e.target.value)
                                                handleChange(e)
                                            }} id='phone-number' />
                                            <p className={phoneAlert}>Please Enter Your Phone Number</p>
                                            <p className={invalidPhoneAlert}>Please Enter 10 Digit Valid Phone Number</p>
                                        </div>
                                        <div className='form-ele'>
                                            <label for="enquiry-regarding">Enquiry Regarding</label>
                                            <select name='enquiryRegarding' onClick={(e) => {
                                                setEnquiryAlert('alert-hide');
                                                setEnquiry(e.target.value);
                                                handleChange(e)
                                            }} id='enquiry-regarding'>
                                                <option value="Other">--Select Specialist--</option>
                                                <option value="Other">Other</option>
                                                <option value="Gynecologist">Gynecologist</option>
                                                <option value="Dietician">Dietician</option>
                                                <option value="Pathology">Pathology Lab</option>
                                                <option value="Physiotherapist">Physiotherapist</option>
                                                <option value="Physician">Physician</option>
                                                <option value="Orthopedics">Orthopedics</option>
                                                <option value="Pediatrician">Pediatrician</option>
                                                <option value="Diagnostic">Diagnostic</option>
                                                <option value="Dentist">Dentist</option>
                                            </select>
                                            <p className={enquiryAlert}>Please Select Specialist</p>
                                        </div>
                                        <div className='form-ele'>
                                            <label for="description">Description</label>
                                            <textarea type='text' name='description' value={formData.description} onChange={(e) => {
                                                setDescAlert('alert-hide');
                                                setDesc(e.target.value);
                                                handleChange(e)
                                            }} rows={4} id='description'></textarea>
                                            <p className={descAlert}>Describe Your Issue</p>
                                        </div>
                                        <div className='hide-timestamp'>
                                            <label htmlFor="timeStamp">Time Stamp:</label>
                                            <input type="Date" name="timeStamp" value={formData.timeStamp} onChange={(e) => {
                                                handleChange(e)
                                            }} disabled />
                                        </div>
                                        <div className='form-ele'>
                                            <button type='submit'>Submit</button>
                                        </div>
                                    </form>
                                </div>
                                <div className={loadingView}>
                                    <HashLoader color='#007902' />
                                    <h5>Submitting Your Response ...</h5>
                                </div>
                                <div className={thanksView}>
                                    <h1>Thanks for Response</h1>
                                    <img src={ValidCheck} alt='Check Arrow Image' />
                                    <h5>Our Team will Reach You Soon.</h5>
                                </div>
                            </div>
                            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                                <div className="h-100">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27398.24281533929!2d73.73753438599832!3d18.649274945983564!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bbbd82f71c2d%3A0x189e0d1a751de23f!2sMediClinic!5e0!3m2!1sen!2sin!4v1727674786645!5m2!1sen!2sin" width="625" height="675" style={{ border: '0', borderRadius: '5px' }} allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade" className="contact-map" title='Google Map'></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- contact End --> */}

            {/* <!-- Footer Start --> */}
            <div className="container-fluid bg-dark text-light footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-4 col-md-4">
                            <h5 className="text-light mb-4">Address</h5>
                            <a href="https://maps.app.goo.gl/Bm2AJZaUszMtkwfp7" target="_blank" aria-label="Address">
                                <p className="mb-2 color-white"><i className="fa fa-map-marker-alt me-3"></i>Mediclinic, Shop No. 11, 12, Shantai Classic, Maske Vasti Rd, Beside Medinilla Healthcare Pvt. Ltd. Ravet Pune - 412101</p>
                            </a>
                            <a href="tel:+919145324365" target="_blank" aria-label="Contact Number">
                                <p className="mb-2 color-white"><i className="fa fa-phone-alt me-3"></i>+91 91453 24365</p>
                            </a>
                            <a href="mailto:ravet.mediclinic@gmail.com" target="_blank" aria-label="Email Address">
                                <p className="mb-2 color-white"><i className="fa fa-envelope me-3"></i>info@mediclinic24.com</p>
                            </a>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <h5 className="text-light mb-4">Services</h5>
                            <div className="footer-service">
                                <div className="col">
                                    <a className="btn btn-link" href="#gynecologist" aria-label="Gynecologist">Gynecologist</a>
                                    <a className="btn btn-link" href="#dietician" aria-label="Dietician">Dietician</a>
                                    <a className="btn btn-link" href="#pathology" aria-label="Pathology Lab">Pathology Lab</a>
                                </div>
                                <div className="col">
                                    <a className="btn btn-link" href="#physiotherapist" aria-label="Physiotherapist">Physiotherapist</a>
                                    <a className="btn btn-link" href="#physician" aria-label="Physician">Physician</a>
                                    <a className="btn btn-link" href="#orthopedics" aria-label="Orthopedics">Orthopedics</a>
                                </div>
                                <div className="col">
                                    <a className="btn btn-link" href="#pediatrician" aria-label="Pediatrician">Pediatrician</a>
                                    <a className="btn btn-link" href="#diagnostic" aria-label="Diagnostic">Diagnostic</a>
                                    <a className="btn btn-link" href="#dentist" aria-label="Dentist">Dentist</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2">
                            <h5 className="text-light mb-4">Quick Links</h5>
                            <a className="btn btn-link" href="#about" aria-label="About Us">About Us</a>
                            <a className="btn btn-link" href="#contact" aria-label="Contact Us">Contact Us</a>
                            <a className="btn btn-link" href="#service" aria-label="Our Services">Our Services</a>
                            <div className="d-flex pt-2">
                                <a className="btn btn-outline-light btn-social rounded-circle" href="https://facebook.com/61568338618033" target="_blank" aria-label="Facebook"><i className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-outline-light btn-social rounded-circle" href="https://wa.me/919145324365" target="_blank"><i className="fab fa-whatsapp" aria-label="Whatsapp"></i></a>
                                <a className="btn btn-outline-light btn-social rounded-circle" href="https://www.instagram.com/mediclinic2024/" target='_blank'><i className="fab fa-instagram" aria-label="Instagram"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="copyright">
                        <div className="row">
                            <div className="col-md-12 text-center text-md-start mb-3 mb-md-0">
                                &copy; <a className="border-bottom" href="https://www.mediclinic24.com/" aria-label="mediclinic24.com">Mediclinic24</a> , All Right Reserved. Designed By <a className="border-bottom" href="https://www.instagram.com/madcoders2024/" aria-label="MADCoders" target='_blank'>MADCoders</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Footer End --> */}
        </div>
    )
}

export default Home